const homeLogo = [
  { d: "15.4 213.35 0 240.02 30.79 240.02 15.4 213.35" },
  { d: "30.79 240.02 15.4 213.35 46.19 213.35 30.79 240.02" },
  { d: "30.79 186.69 15.4 213.35 46.19 213.35 30.79 186.69" },
  { d: "46.19 213.34 30.79 240 61.59 240 46.19 213.34" },
  { d: "46.19 213.34 30.79 186.67 61.59 186.67 46.19 213.34" },
  { d: "61.59 186.69 46.19 213.35 76.98 213.35 61.59 186.69" },
  { d: "61.59 240 46.19 213.34 76.98 213.34 61.59 240" },
  { d: "76.98 213.34 61.59 240 92.38 240 76.98 213.34" },
  { d: "46.19 159.99 30.79 186.65 61.59 186.65 46.19 159.99" },
  { d: "61.59 186.65 46.19 159.99 76.98 159.99 61.59 186.65" },
  { d: "76.98 159.99 61.59 186.65 92.38 186.65 76.98 159.99" },
  { d: "76.98 213.32 61.59 186.65 92.38 186.65 76.98 213.32" },
  { d: "92.38 186.67 76.98 160 107.77 160 92.38 186.67" },
  { d: "92.38 186.67 76.98 213.34 107.77 213.34 92.38 186.67" },
  { d: "107.77 213.32 92.38 186.65 123.17 186.65 107.77 213.32" },
  { d: "107.77 160 92.38 186.67 123.17 186.67 107.77 160" },
  { d: "123.17 186.67 107.77 160 138.56 160 123.17 186.67" },
  { d: "92.38 240.02 76.98 213.35 107.77 213.35 92.38 240.02" },
  { d: "61.59 133.34 46.19 160 76.98 160 61.59 133.34" },
  { d: "76.98 160 61.59 133.34 92.38 133.34 76.98 160" },
  { d: "76.98 106.67 61.59 133.34 92.38 133.34 76.98 106.67" },
  { d: "92.38 133.32 76.98 159.99 107.77 159.99 92.38 133.32" },
  { d: "92.38 133.32 76.98 106.65 107.77 106.65 92.38 133.32" },
  { d: "107.77 106.67 92.38 133.34 123.17 133.34 107.77 106.67" },
  { d: "107.77 159.99 92.38 133.32 123.17 133.32 107.77 159.99" },
  { d: "123.17 133.32 107.77 159.99 138.56 159.99 123.17 133.32" },
  { d: "92.38 79.97 76.98 106.63 107.77 106.63 92.38 79.97" },
  { d: "107.77 106.63 92.38 79.97 123.17 79.97 107.77 106.63" },
  { d: "123.17 79.97 107.77 106.63 138.56 106.63 123.17 79.97" },
  { d: "123.17 133.3 107.77 106.63 138.56 106.63 123.17 133.3" },
  { d: "138.56 106.65 123.17 79.98 153.96 79.98 138.56 106.65" },
  { d: "138.56 106.65 123.17 133.32 153.96 133.32 138.56 106.65" },
  { d: "153.96 133.3 138.56 106.63 169.36 106.63 153.96 133.3" },
  { d: "153.96 79.98 138.56 106.65 169.36 106.65 153.96 79.98" },
  { d: "169.36 106.65 153.96 79.98 184.75 79.98 169.36 106.65" },
  { d: "138.56 160 123.17 133.34 153.96 133.34 138.56 160" },
  { d: "107.77 53.4 92.38 80.07 123.17 80.07 107.77 53.4" },
  { d: "123.17 80.07 107.77 53.4 138.56 53.4 123.17 80.07" },
  { d: "123.17 26.73 107.77 53.4 138.56 53.4 123.17 26.73" },
  { d: "138.56 53.38 123.17 80.05 153.96 80.05 138.56 53.38" },
  { d: "138.56 53.38 123.17 26.72 153.96 26.72 138.56 53.38" },
  { d: "153.96 26.73 138.56 53.4 169.36 53.4 153.96 26.73" },
  { d: "153.96 80.05 138.56 53.38 169.36 53.38 153.96 80.05" },
  { d: "169.36 53.38 153.96 80.05 184.75 80.05 169.36 53.38" },
  { d: "138.56 0.03 123.17 26.7 153.96 26.7 138.56 0.03" },
  { d: "153.96 133.35 138.56 160.02 169.36 160.02 153.96 133.35" },
  { d: "169.36 160.02 153.96 133.35 184.75 133.35 169.36 160.02" },
  { d: "169.36 106.68 153.96 133.35 184.75 133.35 169.36 106.68" },
  { d: "184.75 133.34 169.36 160 200.15 160 184.75 133.34" },
  { d: "184.75 133.34 169.36 106.67 200.15 106.67 184.75 133.34" },
  { d: "200.15 106.68 184.75 133.35 215.55 133.35 200.15 106.68" },
  { d: "200.15 160 184.75 133.34 215.55 133.34 200.15 160" },
  { d: "215.55 133.34 200.15 160 230.94 160 215.55 133.34" },
  { d: "184.75 79.98 169.36 106.65 200.15 106.65 184.75 79.98" },
  { d: "153.96 186.63 138.56 159.97 169.36 159.97 153.96 186.63" },
  { d: "169.36 159.97 153.96 186.63 184.75 186.63 169.36 159.97" },
  { d: "169.36 213.3 153.96 186.63 184.75 186.63 169.36 213.3" },
  { d: "184.75 186.65 169.36 159.99 200.15 159.99 184.75 186.65" },
  { d: "184.75 186.65 169.36 213.32 200.15 213.32 184.75 186.65" },
  { d: "200.15 213.3 184.75 186.63 215.55 186.63 200.15 213.3" },
  { d: "200.15 159.99 184.75 186.65 215.55 186.65 200.15 159.99" },
  { d: "215.55 186.65 200.15 159.99 230.94 159.99 215.55 186.65" },
  { d: "184.75 240 169.36 213.34 200.15 213.34 184.75 240" },
  { d: "200.15 106.67 184.75 80 215.55 80 200.15 106.67" },
  { d: "215.55 80 200.15 106.67 230.94 106.67 215.55 80" },
  { d: "215.55 133.34 200.15 106.67 230.94 106.67 215.55 133.34" },
  { d: "230.94 106.68 215.55 80.02 246.34 80.02 230.94 106.68" },
  { d: "230.94 106.68 215.55 133.35 246.34 133.35 230.94 106.68" },
  { d: "246.34 133.34 230.94 106.67 261.73 106.67 246.34 133.34" },
  { d: "246.34 80.02 230.94 106.68 261.73 106.68 246.34 80.02" },
  { d: "261.73 106.68 246.34 80.02 277.13 80.02 261.73 106.68" },
  { d: "230.94 160.03 215.55 133.37 246.34 133.37 230.94 160.03" },
  { d: "200.15 53.38 184.75 80.05 215.55 80.05 200.15 53.38" },
  { d: "215.55 80.05 200.15 53.38 230.94 53.38 215.55 80.05" },
  { d: "215.55 26.72 200.15 53.38 230.94 53.38 215.55 26.72" },
  { d: "230.94 53.37 215.55 80.03 246.34 80.03 230.94 53.37" },
  { d: "230.94 53.37 215.55 26.7 246.34 26.7 230.94 53.37" },
  { d: "246.34 26.72 230.94 53.38 261.73 53.38 246.34 26.72" },
  { d: "246.34 80.03 230.94 53.37 261.73 53.37 246.34 80.03" },
  { d: "261.73 53.37 246.34 80.03 277.13 80.03 261.73 53.37" },
  { d: "230.94 0.02 215.55 26.68 246.34 26.68 230.94 0.02" },
  { d: "246.34 133.37 230.94 160.03 261.73 160.03 246.34 133.37" },
  { d: "261.73 160.03 246.34 133.37 277.13 133.37 261.73 160.03" },
  { d: "261.73 106.7 246.34 133.37 277.13 133.37 261.73 106.7" },
  { d: "277.13 133.35 261.73 160.02 292.53 160.02 277.13 133.35" },
  { d: "277.13 133.35 261.73 106.68 292.53 106.68 277.13 133.35" },
  { d: "292.53 106.7 277.13 133.37 307.92 133.37 292.53 106.7" },
  { d: "292.53 160.02 277.13 133.35 307.92 133.35 292.53 160.02" },
  { d: "307.92 133.35 292.53 160.02 323.32 160.02 307.92 133.35" },
  { d: "277.13 80 261.73 106.67 292.53 106.67 277.13 80" },
  { d: "246.34 186.62 230.94 159.95 261.73 159.95 246.34 186.62" },
  { d: "261.73 159.95 246.34 186.62 277.13 186.62 261.73 159.95" },
  { d: "261.73 213.28 246.34 186.62 277.13 186.62 261.73 213.28" },
  { d: "277.13 186.63 261.73 159.97 292.53 159.97 277.13 186.63" },
  { d: "277.13 186.63 261.73 213.3 292.53 213.3 277.13 186.63" },
  { d: "292.53 213.28 277.13 186.62 307.92 186.62 292.53 213.28" },
  { d: "292.53 159.97 277.13 186.63 307.92 186.63 292.53 159.97" },
  { d: "307.92 186.63 292.53 159.97 323.32 159.97 307.92 186.63" },
  { d: "277.13 239.99 261.73 213.32 292.53 213.32 277.13 239.99" },
  { d: "292.53 106.68 277.13 80.02 307.92 80.02 292.53 106.68" },
  { d: "307.92 80.02 292.53 106.68 323.32 106.68 307.92 80.02" },
  { d: "307.92 133.35 292.53 106.68 323.32 106.68 307.92 133.35" },
  { d: "323.32 106.7 307.92 80.03 338.71 80.03 323.32 106.7" },
  { d: "323.32 106.7 307.92 133.37 338.71 133.37 323.32 106.7" },
  { d: "338.71 133.35 323.32 106.68 354.11 106.68 338.71 133.35" },
  { d: "338.71 80.03 323.32 106.7 354.11 106.7 338.71 80.03" },
  { d: "354.11 106.7 338.71 80.03 369.51 80.03 354.11 106.7" },
  { d: "323.32 160.05 307.92 133.38 338.71 133.38 323.32 160.05" },
  { d: "292.53 53.37 277.13 80.03 307.92 80.03 292.53 53.37" },
  { d: "307.92 80.03 292.53 53.37 323.32 53.37 307.92 80.03" },
  { d: "307.92 26.7 292.53 53.37 323.32 53.37 307.92 26.7" },
  { d: "323.32 53.35 307.92 80.02 338.71 80.02 323.32 53.35" },
  { d: "323.32 53.35 307.92 26.68 338.71 26.68 323.32 53.35" },
  { d: "338.71 26.7 323.32 53.37 354.11 53.37 338.71 26.7" },
  { d: "338.71 80.02 323.32 53.35 354.11 53.35 338.71 80.02" },
  { d: "354.11 53.35 338.71 80.02 369.51 80.02 354.11 53.35" },
  { d: "323.32 0 307.92 26.67 338.71 26.67 323.32 0" },
  { d: "338.71 26.72 323.32 0.05 354.11 0.05 338.71 26.72" },
  { d: "354.11 0.05 338.71 26.72 369.51 26.72 354.11 0.05" },
  { d: "354.11 53.38 338.71 26.72 369.51 26.72 354.11 53.38" },
  { d: "369.51 26.73 354.11 0.07 384.9 0.07 369.51 26.73" },
  { d: "369.51 26.73 354.11 53.4 384.9 53.4 369.51 26.73" },
  { d: "384.9 53.38 369.51 26.72 400.3 26.72 384.9 53.38" },
  { d: "384.9 0.07 369.51 26.73 400.3 26.73 384.9 0.07" },
  { d: "400.3 26.73 384.9 0.07 415.7 0.07 400.3 26.73" },
  { d: "369.51 80.08 354.11 53.42 384.9 53.42 369.51 80.08" },
  { d: "384.9 53.42 369.51 80.08 400.3 80.08 384.9 53.42" },
  { d: "400.3 80.08 384.9 53.42 415.7 53.42 400.3 80.08" },
  { d: "400.3 26.75 384.9 53.42 415.7 53.42 400.3 26.75" },
  { d: "415.7 53.4 400.3 80.07 431.09 80.07 415.7 53.4" },
  { d: "415.7 53.4 400.3 26.73 431.09 26.73 415.7 53.4" },
  { d: "431.09 26.75 415.7 53.42 446.49 53.42 431.09 26.75" },
  { d: "431.09 80.07 415.7 53.4 446.49 53.4 431.09 80.07" },
  { d: "446.49 53.4 431.09 80.07 461.88 80.07 446.49 53.4" },
  { d: "415.7 0.05 400.3 26.72 431.09 26.72 415.7 0.05" },
  { d: "338.71 133.32 323.32 159.99 354.11 159.99 338.71 133.32" },
  { d: "354.11 159.99 338.71 133.32 369.51 133.32 354.11 159.99" },
  { d: "354.11 106.65 338.71 133.32 369.51 133.32 354.11 106.65" },
  { d: "369.51 133.3 354.11 159.97 384.9 159.97 369.51 133.3" },
  { d: "369.51 133.3 354.11 106.63 384.9 106.63 369.51 133.3" },
  { d: "384.9 106.65 369.51 133.32 400.3 133.32 384.9 106.65" },
  { d: "384.9 159.97 369.51 133.3 400.3 133.3 384.9 159.97" },
  { d: "400.3 133.3 384.9 159.97 415.7 159.97 400.3 133.3" },
  { d: "369.51 79.95 354.11 106.62 384.9 106.62 369.51 79.95" },
  { d: "431.09 26.67 415.7 0 446.49 0 431.09 26.67" },
  { d: "446.49 0 431.09 26.67 461.88 26.67 446.49 0" },
  { d: "446.49 53.33 431.09 26.67 461.88 26.67 446.49 53.33" },
  { d: "461.88 26.68 446.49 0.02 477.28 0.02 461.88 26.68" },
  { d: "461.88 26.68 446.49 53.35 477.28 53.35 461.88 26.68" },
  { d: "477.28 53.33 461.88 26.67 492.68 26.67 477.28 53.33" },
  { d: "477.28 0.02 461.88 26.68 492.68 26.68 477.28 0.02" },
  { d: "492.68 26.68 477.28 0.02 508.07 0.02 492.68 26.68" },
  { d: "461.88 80.03 446.49 53.37 477.28 53.37 461.88 80.03" },
];

// [
//   {
//     d:
//       "M59.099 56.86h13.133l-6.567-11.373-3.283 5.687zM72.232 56.86H59.099l6.566 11.374 3.283-5.687z"
//   },
//   {
//     d:
//       "M65.666 45.487l-3.284 5.687L59.1 56.86l-6.566-11.374h6.566zM78.799 45.487H65.666l6.566 11.373 3.283-5.687zM59.099 56.86H45.965l6.567 11.374 3.283-5.687z"
//   },
//   {
//     d:
//       "M45.966 56.86H59.1l-6.566-11.373-3.283 5.687zM52.533 68.234l3.283-5.687 3.283-5.687 6.567 11.373h-6.567zM39.395 68.236H52.53l-6.567-11.373-3.283 5.687zM32.828 56.862h13.134l-6.567-11.373-3.283 5.687z"
//   },
//   {
//     d:
//       "M45.966 56.86H32.833l6.566 11.374 3.284-5.687zM39.4 45.486l-3.284 5.687-3.283 5.687-6.567-11.373h6.567z"
//   },
//   {
//     d:
//       "M52.532 45.486H39.398l6.567 11.374 3.283-5.687zM45.962 79.61h13.133l-6.566-11.374-3.284 5.687z"
//   },
//   {
//     d:
//       "M59.099 79.607H45.966l6.566 11.373 3.283-5.687zM52.533 68.234l-3.284 5.687-3.283 5.686L39.4 68.234h6.566z"
//   },
//   {
//     d:
//       "M65.665 68.234H52.532l6.566 11.373 3.283-5.687zM72.235 79.616l-3.283 5.687-3.284 5.687-6.566-11.373h6.567z"
//   },
//   { d: "M65.668 90.99l3.283-5.687 3.284-5.687L78.8 90.99h-6.566z" },
//   {
//     d:
//       "M59.102 79.617h13.133l-6.566-11.374-3.284 5.687zM52.535 90.99l3.283-5.686 3.283-5.687 6.567 11.373H59.1zM72.235 79.616l3.283-5.686 3.284-5.687 6.566 11.373h-6.566z"
//   },
//   {
//     d:
//       "M78.801 68.243l-3.283 5.687-3.284 5.687-6.566-11.373h6.567zM85.367 79.617H72.234l6.567 11.373 3.283-5.687zM91.938 68.24l-3.283 5.688-3.283 5.686-6.567-11.373h6.567zM85.372 56.866l-3.284 5.687-3.283 5.687-6.567-11.373h6.567z"
//   },
//   {
//     d:
//       "M78.801 68.243l3.283-5.686 3.284-5.687 6.566 11.373h-6.566zM72.234 56.87h13.133l-6.566-11.374-3.283 5.687z"
//   },
//   {
//     d:
//       "M65.668 68.242l3.284-5.687 3.283-5.687L78.8 68.242h-6.566zM98.505 79.614l-3.284 5.687-3.283 5.687-6.566-11.374h6.566z"
//   },
//   { d: "M91.934 90.99l3.283-5.687 3.284-5.687 6.566 11.374H98.5z" },
//   { d: "M85.367 79.617h13.134l-6.567-11.374-3.283 5.687z" },
//   {
//     d:
//       "M78.801 90.99l3.284-5.687 3.283-5.687 6.567 11.373h-6.567zM45.97 34.116l-3.284 5.687-3.284 5.687-6.566-11.373h6.567z"
//   },
//   { d: "M39.402 45.49l3.283-5.687 3.284-5.687 6.566 11.374H45.97z" },
//   {
//     d:
//       "M32.836 34.117h13.133l-6.566-11.374-3.284 5.687zM26.269 45.49l3.283-5.686 3.283-5.687 6.567 11.373h-6.567zM45.97 34.116l3.282-5.686 3.284-5.687 6.566 11.373h-6.566z"
//   },
//   {
//     d:
//       "M52.535 22.743l-3.283 5.687-3.284 5.687-6.566-11.373h6.567zM59.101 34.117H45.968l6.567 11.373 3.283-5.687zM65.672 22.74l-3.283 5.688-3.283 5.686-6.567-11.373h6.567zM59.106 11.366l-3.284 5.687-3.283 5.687-6.567-11.373h6.567z"
//   },
//   {
//     d:
//       "M52.535 22.743l3.283-5.686 3.284-5.687 6.566 11.373h-6.566zM45.968 11.37h13.133L52.535-.005l-3.283 5.687z"
//   },
//   {
//     d:
//       "M39.402 22.742l3.284-5.687 3.283-5.687 6.566 11.374H45.97zM72.239 34.114l-3.284 5.687-3.283 5.687-6.566-11.374h6.566z"
//   },
//   { d: "M65.668 45.49l3.283-5.687 3.284-5.687L78.8 45.49h-6.567z" },
//   { d: "M59.101 34.117h13.134l-6.567-11.374-3.283 5.687z" },
//   {
//     d:
//       "M52.535 45.49l3.284-5.687 3.283-5.687 6.567 11.373h-6.567zM19.703 79.616l-3.283 5.687-3.284 5.687L6.57 79.617h6.567z"
//   },
//   { d: "M13.136 90.99l3.283-5.687 3.284-5.687 6.566 11.374h-6.566z" },
//   {
//     d:
//       "M6.57 79.617h13.133l-6.566-11.374-3.284 5.687zM.003 90.99l3.283-5.686 3.283-5.687 6.567 11.373H6.569zM19.703 79.616l3.283-5.686 3.284-5.687 6.566 11.373H26.27z"
//   },
//   {
//     d:
//       "M26.27 68.243l-3.284 5.687-3.284 5.687-6.566-11.373h6.567zM32.835 79.617H19.702L26.27 90.99l3.283-5.687zM39.406 68.24l-3.283 5.688-3.283 5.686-6.567-11.373h6.567zM32.84 56.866l-3.284 5.687-3.283 5.687-6.567-11.373h6.567z"
//   },
//   {
//     d:
//       "M26.27 68.243l3.282-5.686 3.284-5.687 6.566 11.373h-6.566zM19.702 56.87h13.133L26.27 45.495l-3.283 5.687z"
//   },
//   {
//     d:
//       "M13.136 68.242l3.284-5.687 3.283-5.687 6.566 11.374h-6.566zM45.973 79.614l-3.284 5.687-3.283 5.687-6.566-11.374h6.566z"
//   },
//   { d: "M39.402 90.99l3.283-5.687 3.284-5.687 6.566 11.374h-6.567z" },
//   { d: "M32.835 79.617H45.97l-6.567-11.374-3.283 5.687z" },
//   { d: "M26.27 90.99l3.283-5.687 3.283-5.687 6.567 11.373h-6.567z" }
// ];

export default homeLogo;
