import React from "react";

// import { css } from "@emotion/core";

import homeLogoPaths from "./home_logo";
import { gsap } from "gsap/all";
import "./home_logo.scss";

// function getRandom(min, max) {
//   return Math.random() * (max - min) + min;
// }
class HomeLogo extends React.Component {
  constructor(props) {
    super(props);
    this.paths = [];
    this.logoTl = new gsap.timeline({
      repeat: -1,
      yoyo: false,
    });
  }
  componentDidMount() {
    // const colours = ["#1f2528", "#e7d5bf", "#ff7601"];
    // const colorFlash = paths => {
    //   var tl = new gsap.timeline({ repeat: -1 });
    //   var colours = ["#1f2528", "#e7d5bf", "#ff7601"];
    //   paths.map(path => {
    //     return tl.to(path, {
    //       duration: 0.01,
    //       repeatRefresh: true,
    //       fill: colours[Math.floor(Math.random() * colours.length)]
    //     });
    //     // .to(path, {
    //     //   duration: 0.1,
    //     //   repeatRefresh: true,
    //     //   fill: colours[Math.floor(Math.random() * colours.length)]
    //     // })
    //     // .to(path, {
    //     //   duration: 0.1,
    //     //   repeatRefresh: true,
    //     //   fill: colours[Math.floor(Math.random() * colours.length)]
    //     // });
    //   });
    //   return tl;
    // };
    // this.logoTl.to(this.paths, {
    //   duration: 1,
    //   repeatRefresh: true,
    //   fill: colours[Math.floor(Math.random() * colours.length)]
    // });
    // colorFlash(this.paths);
    this.logoTl
      .to(this.paths, {
        fill: "#ff7601",
        autoAlpha: 1,
        // rotate: 360,
        repeatRefresh: true,
        // transformOrigin: "center center",
        stagger: { amount: 1, from: "random" },
        // stagger: 0.01,
        ease: "power4.inOut",
        duration: 1,
      })
      .to(this.paths, {
        rotate: 360 * 2,
        fill: "#ff7601",
        // stroke: "#ff7601",
        strokeWidth: 2,
        ease: "power4.inOut",
        stagger: { amount: 1, from: "random" },
        // delay: 2,
        duration: 10,
      })
      .to(this.paths, {
        rotate: 360,
        fill: "#e7d5bf",
        // duration: 5,
        repeatRefresh: true,
        stagger: {
          amount: 2,
          from: "random",
        },
        ease: "slow (0.7,0.7,true)",
        transformOrigin: "center center",
      })
      .to(this.paths, {
        fill: "#ff7601",
        stroke: "#ff7601",
        duration: 2,
      })
      .to(this.paths, {
        stagger: {
          amount: 0.5,
          from: "center",
        },
        // delay: 2,
        duration: 5,
        stroke: "#e7d5bf",
        strokeWidth: 2,
      })
      // .to(this.paths, {
      //   // rotate: 360,
      //   // transformOrigin: "center center",
      //   fill: "#e7d5bf",
      //   ease: "power4.inOut",
      //   duration: 2,
      //   stagger: { amount: 0.5, from: "random" }
      // })

      .to(this.paths, {
        rotate: 360 * 20,
        transformOrigin: "center center",
        duration: 4,
        ease: "power4.in",
        fill: "#1f2528",
        stroke: "#1f2528",
        stagger: { amount: 0.1, from: "random" },
      });
    // .to(this.paths, {
    //   fill: "#1f2528",
    //   stagger: 0.001,
    //   delay: 1
    // })
    // .to(this.paths, { rotate: 360, duration: 2 });
  }
  render() {
    return (
      <>
        <svg
          className="main-logo"
          width="200"
          height="200"
          viewBox="0 0 508.07 240.02"
        >
          {homeLogoPaths.map((path, i) => {
            return (
              <polygon
                ref={path => (this.paths[i] = path)}
                key={i}
                points={path.d}
              />
            );
          })}
        </svg>
      </>
    );
  }
}
export default HomeLogo;
