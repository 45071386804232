import React from "react";
import HomeLogo from "../components/HomeLogo/HomeLogo";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import SEO from "../components/Seo/Seo";

class NotFoundPage extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="center-content">
          <SEO title="404: Not found" />
          <HomeLogo />
          <h1>OOOPS, THAT PAGE DOESN'T EXIST</h1>

          <p>
            <AniLink fade to="/" duration={1}>
              Head back to the site
            </AniLink>
          </p>
        </div>
      </div>
    );
  }
}

export default NotFoundPage;
